.blockCaption {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 48px;
  z-index: 10;
 
}
.title {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 87px;
  color: #012A5A;
  text-align: center;
}

.subtext {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #012A5A;
  text-align: center;
}

.lottieWrapper {position: relative;}

.rocket {position: absolute; margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; z-index: 10; width: 200px;}
.space {
  pointer-events: none;
  z-index: -1;
}

@media (prefers-reduced-motion: no-preference) {
  .space {
    animation: space-spin infinite 260s linear;
  }
}


@keyframes space-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.floating {   
  animation-name: floating; 
  animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in-out; 
 } 

@keyframes floating { 
  0% { transform: translate(0,  0px); } 
  50%  { transform: translate(0, 15px); } 
  100%   { transform: translate(0, -0px); }     
} 


@media (max-width: 991px) {
  .rocket { top:-100px; width: 250px; margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;} 
  .space {  margin-top:-120px; width: 350px;} 
  .lottieWrapper {height: 250px; align-self: center;}
}

@media (min-width: 992px) {
  .blockCaption {
    font-size: 50px;
    line-height: 64px;
    max-width: 508px;
  } 
  .rocket {width: 350px; top:90px;} 
}

@media (min-width: 1400px) {
  .blockCaption {
    font-size: 64px;
    line-height: 78px;
    max-width: 508px;
  }  
  .rocket {width: unset; top:80px;} 
}