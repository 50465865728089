.link {
   color: #012A5A;
   text-decoration: none;
   font-family: 'Avenir';
   cursor: pointer;
}
.link:hover {
    color: #012A5A;
}

.link.active {
    font-family: 'Avenir Next';
    font-size: 18px;
 }
