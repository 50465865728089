@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/Avenir.ttc');
}
@font-face {
  font-family: 'Avenir Next';
  src: url('assets/fonts/Avenir_Next.ttc');
}

body {
  margin: 0;
  font-family: -apple-system, Avenir, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #B7DFFF;
  color: #012A5A;
}


html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
.mainWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
header {}
main {
  flex: auto;
}
footer {
  
}


.btn-flat {
  width: auto;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  padding-left:40px;
  padding-right: 40px;
  background: #E9F5FE;
  border: 3px solid #012A5A;
  box-shadow: inset 0px 0px 0px 5px #FFFFFF;
  border-radius: 30px;
}
.btn-flat:hover {
  border: 3px solid #79b7ff;
}

